import React from "react";
import { graphql, Link } from "gatsby";
import { Section, BreadCrumb, MarkdownContent } from "../components/section";
import Layout from "../components/layout";
import SEO from "../components/seo";
import generateHTML from "../utils/generateHTML";
import Content, { HTMLContent } from "../components/Content";

const ContentPage = ({ data }) => {
  const pageData = data.contentfulContentPage;
  const PostContent = HTMLContent || Content;
  return (
    <Layout>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <Section pt="140px" pb="90px" xpt="110px" xpb="60px">
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link>/<span>{pageData.title}</span>
          </BreadCrumb>
          {/* <SectionPageTitle mt="30px" mb="0px" textAlign="center">{pageData.title}</SectionPageTitle> */}
          <MarkdownContent maxWidth="1120px" mt="30px">
            <PostContent
              content={generateHTML(pageData.content.childMarkdownRemark.html)}
            />
          </MarkdownContent>
        </div>
      </Section>
    </Layout>
  );
};

export default ContentPage;
export const pageQuery = graphql`
  query ContentPageQuery($id: String!) {
    contentfulContentPage(id: { eq: $id }) {
      url
      metaTitle
      metaDescription
      title
      heroBackground {
        fluid {
          aspectRatio
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
